import { Injectable, inject, signal } from '@angular/core';
import { finalize, forkJoin, tap } from 'rxjs';

import { BookingRequestApiService } from './booking-request-api.service';
import { statusConverter } from './booking-request.converter';
import {
  GoogleAnalyticsEventCategory,
  GoogleAnalyticsEventName,
  GoogleAnalyticsService,
} from '../../google-analytics.service';
import { RoomDetailsModel } from '../../room-management/model/rooms.model';
import { RoomsApiService } from '../../room-management/services/rooms-api.service';
import { ChatMessageApiRequestModel } from '../model/booking-request-api.model';
import {
  BookingRequestDetailsModel,
  BookingRequestDetailsUpdateModel,
  BookingStatusEnum,
  ChatMessageModel,
  RoomBookingModel,
} from '../model/booking-request.model';

export interface BookingRequestDetailsViewModel {
  bookingDetails: BookingRequestDetailsModel;
  roomDetails: RoomDetailsModel;
}

@Injectable({
  providedIn: 'root',
})
export class BookingRequestService {
  readonly #bookingRequestApiService = inject(BookingRequestApiService);
  readonly #roomsApiService = inject(RoomsApiService);
  readonly #googleAnalyticService = inject(GoogleAnalyticsService);

  readonly #bookingDetailsSignal =
    signal<BookingRequestDetailsViewModel | null>(null);
  readonly #pendingRequestsSignal = signal<RoomBookingModel[]>([]);
  readonly #allBookingsSignal = signal<RoomBookingModel[]>([]);
  readonly #chatMessagesSignal = signal<ChatMessageModel[]>([]);
  readonly #isRoomBookingDataLoadingSignal = signal(false);
  readonly #isDetailsDataLoadingSignal = signal(false);
  readonly #isMessageLoadingSignal = signal(false);
  readonly #roomDetailsSignal = signal<RoomDetailsModel | null>(null);
  #roomBookingsSnapshot: RoomBookingModel[] = [];

  get allBookings() {
    return this.#allBookingsSignal.asReadonly();
  }

  get chatMessages() {
    return this.#chatMessagesSignal.asReadonly();
  }

  get bookingDetails() {
    return this.#bookingDetailsSignal.asReadonly();
  }

  get roomDetails() {
    return this.#roomDetailsSignal.asReadonly();
  }

  get pendingRequests() {
    return this.#pendingRequestsSignal.asReadonly();
  }

  get isDetailsDataLoading() {
    return this.#isDetailsDataLoadingSignal.asReadonly();
  }

  get isRoomBookingDataLoading() {
    return this.#isRoomBookingDataLoadingSignal.asReadonly();
  }

  get isMessageLoading() {
    return this.#isMessageLoadingSignal.asReadonly();
  }

  public getBookingRequestData(roomId: string, bookingId: number) {
    this.#bookingDetailsSignal.set(null);
    this.#isDetailsDataLoadingSignal.set(true);
    return forkJoin([
      this.#bookingRequestApiService.getBookingDetails(bookingId),
      this.#roomsApiService.getRoomDetails(roomId),
    ]).pipe(
      tap(([bookingRequest, roomDetails]) => {
        this.#bookingDetailsSignal.set({
          bookingDetails: bookingRequest,
          roomDetails: roomDetails,
        });
        this.#roomDetailsSignal.set(roomDetails);
      }),
      finalize(() => this.#isDetailsDataLoadingSignal.set(false))
    );
  }

  public getRoomBookings() {
    this.#isRoomBookingDataLoadingSignal.set(true);
    return this.#bookingRequestApiService.getRoomBookings().pipe(
      tap(roomBookings => {
        this.#pendingRequestsSignal.set(
          roomBookings.filter(
            roomBooking =>
              roomBooking.status.value === BookingStatusEnum.InProgress
          )
        );
        this.#allBookingsSignal.set(roomBookings);
        this.#roomBookingsSnapshot = roomBookings;
      }),
      finalize(() => this.#isRoomBookingDataLoadingSignal.set(false))
    );
  }

  public searchRoomBookings(searchQuery: string) {
    const filtered = this.#roomBookingsSnapshot.filter(
      roomBooking =>
        roomBooking.id === +searchQuery ||
        roomBooking.roomDescription
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        roomBooking.roomName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        roomBooking.eventName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    this.#pendingRequestsSignal.set(
      filtered.filter(
        roomBooking => roomBooking.status.value === BookingStatusEnum.InProgress
      )
    );
    this.#allBookingsSignal.set(filtered);
  }

  public resetSearchRoomBookings() {
    this.#pendingRequestsSignal.set(
      this.#roomBookingsSnapshot.filter(
        roomBooking => roomBooking.status.value === BookingStatusEnum.InProgress
      )
    );
    this.#allBookingsSignal.set(this.#roomBookingsSnapshot);
  }

  public approveBookingRequest(id: number, message: string) {
    this.#isDetailsDataLoadingSignal.set(true);
    return this.#bookingRequestApiService
      .approveRoomBooking({ id, message })
      .pipe(
        tap(bookingRequest => {
          this.updateRequestStatus(
            id,
            bookingRequest.status.value,
            bookingRequest
          );
          this.#googleAnalyticService.trackEvent(
            GoogleAnalyticsEventName.Approve,
            GoogleAnalyticsEventCategory.BookingRequest,
            id
          );
        }),
        finalize(() => this.#isDetailsDataLoadingSignal.set(false))
      );
  }

  public rejectBookingRequest(id: number, message: string) {
    this.#isDetailsDataLoadingSignal.set(true);
    return this.#bookingRequestApiService
      .rejectRoomBooking({ id, message })
      .pipe(
        tap(bookingRequest => {
          this.updateRequestStatus(
            id,
            bookingRequest.status.value,
            bookingRequest
          );
          this.#googleAnalyticService.trackEvent(
            GoogleAnalyticsEventName.Reject,
            GoogleAnalyticsEventCategory.BookingRequest,
            id
          );
        }),
        finalize(() => this.#isDetailsDataLoadingSignal.set(false))
      );
  }

  public cancelBookingRequest(id: number, message: string) {
    this.#isDetailsDataLoadingSignal.set(true);
    return this.#bookingRequestApiService
      .cancelRoomBooking({ id, message })
      .pipe(
        tap(bookingRequest => {
          this.updateRequestStatus(
            id,
            bookingRequest.status.value,
            bookingRequest
          );
          this.#googleAnalyticService.trackEvent(
            GoogleAnalyticsEventName.Cancel,
            GoogleAnalyticsEventCategory.BookingRequest,
            id
          );
        }),
        finalize(() => this.#isDetailsDataLoadingSignal.set(false))
      );
  }

  public updateBookingRequest(
    bookingRequest: BookingRequestDetailsUpdateModel
  ) {
    this.#isDetailsDataLoadingSignal.set(true);
    return this.#bookingRequestApiService
      .updateRoomBooking(bookingRequest)
      .pipe(
        tap(bookingRequest => {
          this.#bookingDetailsSignal.set({
            bookingDetails: bookingRequest,
            roomDetails: this.#bookingDetailsSignal()
              ?.roomDetails as RoomDetailsModel,
          });
          this.updateRequestDetails(bookingRequest);
          this.#googleAnalyticService.trackEvent(
            GoogleAnalyticsEventName.Edit,
            GoogleAnalyticsEventCategory.BookingRequest,
            bookingRequest.id
          );
        }),
        finalize(() => this.#isDetailsDataLoadingSignal.set(false))
      );
  }

  public getMessages(bookingId: number) {
    this.#chatMessagesSignal.set([]);
    this.#isMessageLoadingSignal.set(true);
    return this.#bookingRequestApiService.getMessages(bookingId).pipe(
      tap(chatMessages => this.#chatMessagesSignal.set(chatMessages)),
      finalize(() => this.#isMessageLoadingSignal.set(false))
    );
  }

  public markAllMessagesAsRead(bookingId: number) {
    return this.#bookingRequestApiService
      .markAllMessagesAsRead(bookingId)
      .pipe(tap(chatMessages => this.#chatMessagesSignal.set(chatMessages)));
  }

  public postMessage(message: ChatMessageApiRequestModel) {
    this.#isMessageLoadingSignal.set(true);
    return this.#bookingRequestApiService.postMessage(message).pipe(
      tap(chatMessages => {
        this.#chatMessagesSignal.set(chatMessages);
        this.#googleAnalyticService.trackEvent(
          GoogleAnalyticsEventName.MessageSent,
          GoogleAnalyticsEventCategory.BookingRequest,
          message.bookingId
        );
      }),
      finalize(() => this.#isMessageLoadingSignal.set(false))
    );
  }

  private updateRequestStatus(
    bookingId: number,
    status: BookingStatusEnum,
    bookingRequest: BookingRequestDetailsModel
  ) {
    const allRequests = [...this.#roomBookingsSnapshot];
    const updatedRequests = this.#roomBookingsSnapshot.filter(
      bookingRequest =>
        bookingRequest.status.value === BookingStatusEnum.InProgress &&
        bookingRequest.id !== bookingId
    );
    const updatedRequestIndex = allRequests.findIndex(
      roomBooking => roomBooking.id === bookingId
    );

    allRequests[updatedRequestIndex].status = statusConverter(status);

    this.#bookingDetailsSignal.set({
      bookingDetails: bookingRequest,
      roomDetails: this.#bookingDetailsSignal()
        ?.roomDetails as RoomDetailsModel,
    });
    this.#pendingRequestsSignal.set(updatedRequests);
    this.#allBookingsSignal.set(allRequests);
  }

  private updateRequestDetails(bookingRequest: BookingRequestDetailsModel) {
    const updatedRequestIndex = this.#roomBookingsSnapshot.findIndex(
      roomBooking => roomBooking.id === bookingRequest.id
    );
    this.#roomBookingsSnapshot[updatedRequestIndex] = {
      ...this.#roomBookingsSnapshot[updatedRequestIndex],
      eventName: bookingRequest.eventName,
      startDate: new Date(bookingRequest.startTime).toLocaleString(['en-GB'], {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
      endDate: new Date(bookingRequest.endTime).toLocaleString(['en-GB'], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
      startTime: new Date(bookingRequest.startTime).toLocaleString(['en-GB'], {
        hour: '2-digit',
        minute: '2-digit',
      }),
      endTime: new Date(bookingRequest.endTime).toLocaleString(['en-GB'], {
        hour: '2-digit',
        minute: '2-digit',
      }),
    };

    const allRequests = [...this.#roomBookingsSnapshot];
    const updatedRequests = this.#roomBookingsSnapshot.filter(
      roomBooking => roomBooking.status.value === BookingStatusEnum.InProgress
    );

    this.#pendingRequestsSignal.set(updatedRequests);
    this.#allBookingsSignal.set(allRequests);
  }
}
