<h2 rds-dialog-header>
  Bookly confirmation #{{ bookingData.bookingId }}
  @if (bookingDetails(); as bookingDetails) {
    <rds-badge
      size="m"
      type="minimal"
      [color]="bookingDetails.bookingDetails.status.color"
      [label]="bookingDetails.bookingDetails.status.text">
    </rds-badge>
  }
</h2>

<div rds-dialog-icon-placeholder>
  <button rds-icon-button size="m" [rds-dialog-close]="null">
    <rds-icon icon="close" namespace="outlined"></rds-icon>
  </button>
</div>
<div rds-dialog-content>
  <div class="buttons-container">
    @if (bookingDetails(); as bookingDetails) {
      @if (
        bookingDetails.bookingDetails.status.value ===
        BookingStatusEnum.Approved
      ) {
        <button
          rds-secondary-button
          size="m"
          (click)="openModal(BookingRequestActionModalEnum.Cancel)">
          Cancel Booking
        </button>
      }
      @if (
        !isEditMode() &&
        bookingDetails.bookingDetails.status.value ===
          BookingStatusEnum.InProgress
      ) {
        <button
          rds-secondary-button
          size="m"
          (click)="openModal(BookingRequestActionModalEnum.Reject)">
          Reject
        </button>
        <button
          rds-primary-button
          size="m"
          (click)="openModal(BookingRequestActionModalEnum.Confirm)">
          Approve
        </button>
      }
    }
  </div>
  <rds-tabs>
    <rds-tab label="Detail">
      <div class="details">
        @if (bookingDetails() && roomLayouts()) {
          <app-event-details
            [eventDetails]="bookingDetails()"
            [roomLayouts]="roomLayouts()"
            (isEditModeEnabled)="setEditMode($event)"></app-event-details>
        }
        @if (isLoading()) {
          <div class="loader">
            <rds-progress-spinner></rds-progress-spinner>
          </div>
        }
      </div>
    </rds-tab>
    <rds-tab
      #messagesTab
      label="Messages"
      [active]="bookingData.setActiveTab === NotificationSourceTypeEnum.Chat">
      <div class="details">
        @if (isMessageLoading()) {
          <div class="loader">
            <rds-progress-spinner></rds-progress-spinner>
          </div>
        }
        <app-messages
          [bookingRequestStatus]="
            bookingDetails()?.bookingDetails?.status?.value
          "
          [chatMessages]="chatMessages()"
          (sendMessage)="postMessage($event)"></app-messages>
      </div>
    </rds-tab>
    <rds-tab label="Calendar">
      <div class="details">
        @if (isCalendarLoading()) {
          <div class="loader">
            <rds-progress-spinner></rds-progress-spinner>
          </div>
        }
        <app-room-bookings-calendar
          [roomBooking]="bookingDetails()"></app-room-bookings-calendar>
      </div>
    </rds-tab>
  </rds-tabs>
</div>
