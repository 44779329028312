<div class="buttons-container">
  <button
    rds-text-button
    mwlCalendarPreviousView
    [view]="'week'"
    [(viewDate)]="viewDate"
    (viewDateChange)="viewDateChange(viewDate)">
    PREVIOUS WEEK
  </button>
  <rds-form-field size="m" labelPosition="none">
    <input
      rdsInput
      [rdsDatepicker]="picker"
      [(ngModel)]="viewDate"
      (ngModelChange)="viewDateChange($event)" />
    <rds-datepicker-toggle rds-control-suffix />
    <rds-datepicker #picker />
  </rds-form-field>
  <button
    rds-text-button
    mwlCalendarNextView
    [view]="'week'"
    [(viewDate)]="viewDate"
    (viewDateChange)="viewDateChange(viewDate)">
    NEXT WEEK
  </button>
</div>
<mwl-calendar-week-view
  [viewDate]="viewDate"
  [events]="roomEvents()"
  [dayStartHour]="7"
  [eventTemplate]="customCellTemplate"
  [weekStartsOn]="1">
</mwl-calendar-week-view>

<ng-template #customCellTemplate let-weekEvent="weekEvent">
  @if (weekEvent.event.meta.type === CalendarEventTypeEnum.BlockingEvent) {
    <div
      class="cal-event custom-event blocking-time"
      [rdsTooltip]="
        'BLOCKING TIME, ' +
        weekEvent.event.meta.timeStart +
        ' - ' +
        weekEvent.event.meta.timeEnd
      ">
      <p class="ui-body-s-bold">BLOCKING TIME</p>
      <p>
        {{ weekEvent.event.meta.timeStart }} -
        {{ weekEvent.event.meta.timeEnd }}
      </p>
    </div>
  } @else {
    <div
      class="cal-event custom-event"
      [class]="weekEvent.event.meta.status"
      [ngClass]="
        weekEvent.event.meta.bookingId === bookingRequest?.bookingDetails?.id &&
        weekEvent.event.meta.status === 'IN_PROGRESS'
          ? 'current'
          : null
      "
      [rdsTooltip]="
        weekEvent.event.meta.eventName +
        ', ' +
        'Booked by ' +
        weekEvent.event.meta.userFullName +
        ', ' +
        weekEvent.event.meta.timeStart +
        ' - ' +
        weekEvent.event.meta.timeEnd
      ">
      <p class="ui-body-s-bold">
        {{
          weekEvent.event.meta.eventName ??
            'Booked by: ' + weekEvent.event.meta.userFullName
        }}
      </p>
      <p>
        {{ weekEvent.event.meta.timeStart }} -
        {{ weekEvent.event.meta.timeEnd }}
      </p>
    </div>
  }
</ng-template>
