<h2 rds-dialog-header>Edit Meeting room</h2>
<div rds-dialog-icon-placeholder>
  <button rds-icon-button size="m" [rds-dialog-close]="null">
    <rds-icon icon="close" namespace="outlined"></rds-icon>
  </button>
</div>
<rds-divider></rds-divider>
<div rds-dialog-content [formGroup]="roomConfigForm">
  <div class="room-details-container">
    @if (isLoading()) {
      <div class="loader">
        <rds-progress-spinner></rds-progress-spinner>
      </div>
    }

    @if (roomSettings) {
      <rds-form-field labelPosition="top">
        <rds-control-label>Location/Site</rds-control-label>
        <input rdsInput [value]="roomSettings.siteName" disabled />
      </rds-form-field>
      <rds-form-field labelPosition="top">
        <rds-control-label>Building/Floor</rds-control-label>
        <input
          rdsInput
          [value]="roomSettings.buildingName + ' / ' + roomSettings.floorName"
          disabled />
      </rds-form-field>
      <rds-form-field labelPosition="top">
        <rds-control-label>Room Name</rds-control-label>
        <input rdsInput [value]="roomSettings.roomName" disabled />
      </rds-form-field>
      <rds-form-field labelPosition="top">
        <rds-control-label>Room Type</rds-control-label>
        <input rdsInput [value]="roomSettings.roomType" disabled />
      </rds-form-field>
    }

    <div>
      <p>Room status</p>
      <rds-radio-group class="d-flex flex-column" formControlName="active">
        <rds-radio-button [value]="true">Active</rds-radio-button>
        <rds-radio-button [value]="false">Inactive</rds-radio-button>
      </rds-radio-group>
    </div>
    <!--    TODO uncomment when ready -->
    <!--    <rds-form-field labelPosition="top">-->
    <!--      <rds-control-label>Room available for user group</rds-control-label>-->
    <!--      <input rdsInput value="Admin group" disabled />-->
    <!--    </rds-form-field>-->
    <rds-form-field labelPosition="top">
      <rds-control-label>Description</rds-control-label>
      <textarea rdsInput formControlName="description"></textarea>
      <!--      <input rdsInput formControlName="description" />-->
    </rds-form-field>
    <div>
      <p>Automatic approval</p>
      <rds-radio-group
        class="d-flex flex-column"
        formControlName="automaticApproval">
        <rds-radio-button [value]="true" checked>Yes</rds-radio-button>
        <rds-radio-button [value]="false">No</rds-radio-button>
      </rds-radio-group>
    </div>
  </div>
  <div class="table">
    <app-layout-table
      (selectedLayout)="updateDefaultLayout($event)"
      (removeLayout)="removeLayout($event)"
      [roomLayout]="roomLayouts()"
      [defaultLayout]="
        roomSettings?.manageSpaceConfiguration?.defaultLayoutId
      "></app-layout-table>
  </div>
  <div class="room-details-container">
    <button rds-primary-button size="m" (click)="openLayoutUploadDialog()">
      Upload new room layout
    </button>
    <div>
      <p>Room blocking time</p>
      <rds-radio-group
        class="d-flex flex-column"
        formControlName="setBlockingTime"
        (buttonChanged)="toggleRoomBlockingTime($event)">
        <rds-radio-button [value]="true" checked>Yes</rds-radio-button>
        <rds-radio-button [value]="false">No</rds-radio-button>
      </rds-radio-group>
    </div>
    @if (roomConfigForm.controls.setBlockingTime.value) {
      <div formArrayName="blockingTimes">
        <p>Specify daily block time</p>
        @for (
          time of roomConfigForm.controls.blockingTimes.controls;
          track time;
          let last = $last
        ) {
          <div class="time-container" [formGroup]="time">
            <rds-form-field labelPosition="top">
              <rds-control-label>From</rds-control-label>
              <input rdsInput formControlName="from" type="time" />
            </rds-form-field>
            <rds-form-field labelPosition="top">
              <rds-control-label>To</rds-control-label>
              <input rdsInput formControlName="to" type="time" />
            </rds-form-field>
            @if (last) {
              <button rds-icon-button size="s" (click)="addBlockingTime()">
                <rds-icon namespace="filled" icon="plus"></rds-icon>
              </button>
            }
          </div>
        }
      </div>
    }
    <div>
      <p>Meeting duration time</p>
      <rds-radio-group
        class="d-flex flex-column"
        formControlName="setMeetingDurationRangeTime">
        <rds-radio-button [value]="true">Yes</rds-radio-button>
        <rds-radio-button [value]="false">No</rds-radio-button>
      </rds-radio-group>
      @if (roomConfigForm.controls.setMeetingDurationRangeTime.value) {
        <div
          class="meeting-duration-form"
          [formGroup]="roomConfigForm.controls.durationRange.controls.min">
          <p class="ui-label-m-bold">Minimum</p>
          <div class="meeting-duration-controls">
            <rds-form-field labelPosition="top">
              <rds-control-label>Days</rds-control-label>
              <input rdsInput formControlName="days" min="0" type="number" />
            </rds-form-field>
            <rds-form-field labelPosition="top">
              <rds-control-label>Hours</rds-control-label>
              <input rdsInput formControlName="hours" min="0" type="number" />
            </rds-form-field>
            <rds-form-field labelPosition="top">
              <rds-control-label>Minutes</rds-control-label>
              <input rdsInput formControlName="minutes" min="0" type="number" />
            </rds-form-field>
          </div>
        </div>
        <div
          class="meeting-duration-form"
          [formGroup]="roomConfigForm.controls.durationRange.controls.max">
          <p class="ui-label-m-bold">Maximum</p>
          <div class="meeting-duration-controls">
            <rds-form-field labelPosition="top">
              <rds-control-label>Days</rds-control-label>
              <input rdsInput formControlName="days" min="0" type="number" />
            </rds-form-field>
            <rds-form-field labelPosition="top">
              <rds-control-label>Hours</rds-control-label>
              <input rdsInput formControlName="hours" min="0" type="number" />
            </rds-form-field>
            <rds-form-field labelPosition="top">
              <rds-control-label>Minutes</rds-control-label>
              <input rdsInput formControlName="minutes" min="0" type="number" />
            </rds-form-field>
          </div>
        </div>
      }
    </div>
  </div>
</div>
<div rds-dialog-actions-container>
  <button rds-secondary-button [rds-dialog-close]="'No'" size="m">
    Cancel
  </button>
  <button
    rds-primary-button
    size="m"
    (click)="updateRoomConfig()"
    [disabled]="roomConfigForm.invalid">
    Save
  </button>
</div>
