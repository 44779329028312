import { ValidationErrors } from '@angular/forms';
import { differenceInMinutes } from 'date-fns';

import {
  BookingDetailsFormGroupModel,
  BookingDetailsFormValueModel,
} from './event-details.component';
import { RoomSettingsDurationRangeModel } from '../../../room-management/model/rooms-api.model';

export function eventDurationValidator(
  min: RoomSettingsDurationRangeModel,
  max: RoomSettingsDurationRangeModel
) {
  return (
    formGroup: BookingDetailsFormGroupModel<BookingDetailsFormValueModel>
  ): ValidationErrors | null => {
    const startHour = formGroup.controls.startHour;
    const endHour = formGroup.controls.endHour;

    const hoursStart = new Date(
      formGroup.controls.startDate.value.toDateString() + ' ' + startHour.value
    );
    const hoursEnd = new Date(
      formGroup.controls.endDate.value?.toDateString() + ' ' + endHour.value
    );

    const diffInMinutes = differenceInMinutes(hoursEnd, hoursStart);

    if (durationToMinutes(min) > diffInMinutes) {
      startHour.setErrors({ error: true });
      endHour.setErrors({ error: true });

      return { durationTooShort: true };
    }
    if (durationToMinutes(max) < diffInMinutes) {
      startHour.setErrors({ error: true });
      endHour.setErrors({ error: true });

      return { durationTooLong: true };
    }

    return null;
  };
}

function durationToMinutes(duration: {
  days: number;
  hours: number;
  minutes: number;
}) {
  return duration.days * 24 * 60 + duration.hours * 60 + duration.minutes;
}
