import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from '../../../environments/environment';
import { NotificationsApiResponseModel } from '../model/notifications-api.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService {
  readonly #http = inject(HttpClient);

  public getNotifications(userEmail: string) {
    return this.#http.get<NotificationsApiResponseModel[]>(
      environment.notificationsApi + userEmail
    );
  }

  public markNotificationAsRead(userEmail: string, notificationId: number) {
    return this.#http.put(
      environment.notificationsApi + userEmail + `/${notificationId}`,
      null
    );
  }

  public markAllNotificationAsRead(userEmail: string) {
    return this.#http.put(environment.notificationsApi + userEmail, null);
  }
}
