import { Injectable, inject, signal } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EMPTY, finalize, first } from 'rxjs';

import { AuthApiService } from './auth-api.service';
import { User } from './auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly #router = inject(Router);
  readonly #authApiService = inject(AuthApiService);
  readonly #activatedRoute = inject(ActivatedRoute);

  readonly #isLoginPendingSignal = signal(false);
  #user: User | null = null;
  #token: string | null = null;

  get isLoginPending() {
    return this.#isLoginPendingSignal.asReadonly();
  }

  get token() {
    return this.#token;
  }

  get user() {
    return this.#user;
  }

  public init() {
    const id_token = localStorage.getItem('id_token');

    if (id_token) {
      this.#token = id_token;
      this.#user = this.#parseJwt(this.#token);
      return EMPTY;
    }

    return this.#activatedRoute.queryParams
      .pipe(first(params => 'code' in params))
      .subscribe(() => {
        const queryParams = this.#activatedRoute.snapshot.queryParams;
        this.#router.navigate(['/']);
        this.requestToken(queryParams);
      });
  }

  public login() {
    this.#authApiService.redirectToGoogle();
  }

  public logout() {
    localStorage.clear();
    this.#router.navigate(['/login']);
  }

  public requestToken(authCode: Params) {
    this.#isLoginPendingSignal.set(true);
    this.#authApiService
      .requestToken(authCode)
      .pipe(
        finalize(() => {
          this.#isLoginPendingSignal.set(false);
        })
      )
      .subscribe(resp => {
        if (resp.idtoken !== 'Please provide a code') {
          localStorage.setItem('id_token', resp.idtoken);
          this.#token = resp.idtoken;
          this.#user = this.#parseJwt(this.#token);
          this.#router.navigate(['/']);
        }
      });
  }

  #parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(code => '%' + ('00' + code.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}
