import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

export const authInterceptor: HttpInterceptorFn = (request, next) => {
  const authService = inject(AuthService);
  if (authService.token && request.url.includes(environment.apiUrl)) {
    request = request.clone({
      setHeaders: {
        Authorization: authService.token,
      },
    });
  }
  return next(request);
};
