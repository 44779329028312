import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import {
  RdsDialogService,
  RdsProgressSpinnerModule,
  RdsSearchEvent,
  RdsSearchModule,
  RdsTabModule,
} from '@rds/angular-components';

import { RoomBookingsTableComponent } from '../../components/room-bookings-table/room-bookings-table.component';
import { RoomBookingModel } from '../../model/booking-request.model';
import { BookingRequestService } from '../../services/booking-request.service';
import { BookingRequestDetailsComponent } from '../booking-request-details/booking-request-details.component';

@Component({
  selector: 'app-booking-request',
  templateUrl: './booking-request.component.html',
  styleUrls: ['./booking-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RdsSearchModule,
    RdsTabModule,
    RoomBookingsTableComponent,
    RdsProgressSpinnerModule,
  ],
})
export class BookingRequestComponent implements OnInit {
  readonly #dialogService = inject(RdsDialogService);
  readonly #bookingRequestService = inject(BookingRequestService);
  protected readonly pendingRequests =
    this.#bookingRequestService.pendingRequests;
  protected readonly allBookings = this.#bookingRequestService.allBookings;
  protected readonly isRoomBookingDataLoading =
    this.#bookingRequestService.isRoomBookingDataLoading;

  public ngOnInit() {
    this.#bookingRequestService.getRoomBookings().subscribe();
  }

  protected clickRowHandler(data: RoomBookingModel) {
    this.#dialogService.open(BookingRequestDetailsComponent, {
      disableClose: true,
      size: 'xl',
      maxHeight: '100%',
      data: {
        bookingId: data.id,
        roomDexId: data.roomDexId,
      },
    });
  }

  onSearch(searchEvent: RdsSearchEvent) {
    this.#bookingRequestService.searchRoomBookings(searchEvent.query);
  }

  onClear() {
    this.#bookingRequestService.resetSearchRoomBookings();
  }
}
