import { RoomAvailabilityApiResponseModel } from '../model/rooms-api.model';
import { RoomCalendarEventsModel } from '../model/rooms.model';

export function roomCalendarEventsConverter(
  roomAvailability: RoomAvailabilityApiResponseModel
): RoomCalendarEventsModel[] {
  return roomAvailability.ranges.map(roomEvent => ({
    start: new Date(roomEvent.start),
    end: new Date(roomEvent.end),
    title: roomEvent.userFullName ?? roomEvent.organizer,
    meta: {
      timeStart: `${new Date(roomEvent.start).toLocaleString(['en-GB'], {
        hour: '2-digit',
        minute: '2-digit',
      })}`,
      timeEnd: `${new Date(roomEvent.end).toLocaleString(['en-GB'], {
        hour: '2-digit',
        minute: '2-digit',
      })}`,
      status: roomEvent.status ?? 'unknown',
      type: roomEvent.type ?? 'unknown',
      eventName: roomEvent.eventName,
      userFullName: roomEvent.userFullName ?? roomEvent.organizer,
      bookingId: +roomEvent.bookingId,
    },
  }));
}
