import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  Messaging,
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';

import { NotificationsService } from './notifications/services/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  readonly #notificationService = inject(NotificationsService);
  readonly #firebaseConfig = {
    apiKey: 'AIzaSyBGur9YBBJeFYyphAWFDUOc_7y3cpAWMV0',
    authDomain: 'bookly-fad0a.firebaseapp.com',
    projectId: 'bookly-fad0a',
    storageBucket: 'bookly-fad0a.appspot.com',
    messagingSenderId: '720164160803',
    appId: '1:720164160803:web:58459cf107c684420cb896',
    measurementId: 'G-JBXGRGXJN0',
  };
  readonly #app: FirebaseApp;
  readonly #messaging: Messaging;
  readonly #analytics: Analytics;

  constructor() {
    this.#app = initializeApp(this.#firebaseConfig);
    this.#messaging = getMessaging(this.#app);
    this.#analytics = getAnalytics(this.#app);
  }

  public ngOnInit() {
    this.#requestPermission();
    navigator.serviceWorker
      .register('/assets/firebase-messaging-sw.js')
      .then(registration => {
        getToken(this.#messaging, {
          vapidKey:
            'BHwdVS9hpZdbgVMqWqdPgFz4cehr7QMt4_xi1y8tlgr9lV4hFDKcmRnpiF0kvTNCx_jU4VTzQpx3wOOq4EjbL8c',
          serviceWorkerRegistration: registration,
        }).then(r => console.log(r));
      });

    onMessage(this.#messaging, message => {
      console.log('Message received. ', message);
      this.#notificationService.getNotifications();
    });
  }

  #requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      }
    });
  }
}
