import { BookingStatusApiResponseEnum } from '../../booking-request/model/booking-request-api.model';

export interface RoomApiResponseModel {
  roomDexId: string;
  name: string;
  description: string;
  manageSpace: boolean;
  photo: {
    id: number;
    name: string;
    url: string;
    main: boolean;
  };
  type: string;
  capacity: number;
}

export interface RoomAvailabilityApiResponseModel {
  ranges: [
    {
      start: string;
      end: string;
      organizer: string;
      creator: string;
      bookingId: string;
      type: CalendarEventTypeEnum;
      userFullName: string;
      status: BookingStatusApiResponseEnum;
      eventName: string;
    },
  ];
}

export enum CalendarEventTypeEnum {
  Event = 'EVENT',
  BlockingEvent = 'BLOCKING_EVENT',
}

export interface RoomDetailsApiResponseModel {
  id: number;
  calendarId: string;
  roomDexId: string;
  roomName: string;
  roomNumber: string;
  roomType: string;
  active: boolean;
  capacity: number;
  manageSpace: boolean;
  description: string;
  automaticApproval: boolean;
  siteName: string;
  buildingName: string;
  buildingNumber: string;
  street: string;
  floorCode: string;
  siteTimeZone: string;
  siteTimeZoneOffset: string;
  manageSpaceConfiguration?: RoomSettingsApiRequestModel;
  photos: Photo[];
  features: Feature[];
}

export interface Photo {
  id: number;
  name: string;
  url: string;
  main: boolean;
}

export interface Feature {
  id: number;
  name: string;
}

export interface RoomsByEmailApiResponseModel {
  hostEmail: string;
  roomDexIds: string[];
}

export interface RoomLayoutUploadApiRequestModel {
  fileName: string;
  layoutName: string;
  setupTime: {
    before: number;
    after: number;
  };
  capacity: {
    min: number;
    max: number;
  };
  roomDexId: string;
  adminEmail: string;
}

export interface RoomLayoutUploadApiResponseModel {
  presignedUrl: string;
  roomLayoutDTO: {
    layoutName: string;
    roomDexId: string;
    layoutDexUUID: string;
    imageName: string;
    adminEmail: string;
    capacity: {
      min: number;
      max: number;
    };
    setupTime: {
      before: number;
      after: number;
    };
    createTime: string;
  };
}

export interface RoomLayoutsApiResponseModel {
  layoutDexUUID: string;
  imageURL: string;
  layoutName: string;
  capacity: {
    min: number;
    max: number;
  };
  setupTime: {
    before: number;
    after: number;
  };
}

export interface RoomSettingApiResponseModel {
  dexId: string;
  roomName: string;
  siteName: string;
  buildingName: string;
  floorName: string;
  roomType: string;
  manageSpaceConfiguration?: RoomSettingsApiRequestModel;
}

export interface RoomSettingsApiRequestModel {
  roomDexId: string;
  active?: boolean;
  description?: string;
  automaticApproval?: boolean;
  defaultLayoutId?: string;
  blockingTimes?: RoomSettingsBlockingTimeModel[];
  durationRange?: {
    min: RoomSettingsDurationRangeModel;
    max: RoomSettingsDurationRangeModel;
  };
}

export interface RoomSettingsDurationRangeModel {
  days: number;
  hours: number;
  minutes: number;
}

export interface RoomSettingsBlockingTimeModel {
  from: string;
  to: string;
  uuid: string | null;
}
