import { Routes } from '@angular/router';

import { authGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/components/login/login.component';
import { BookingRequestComponent } from './booking-request/views/booking-request/booking-request.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RoomsListComponent } from './room-management/views/rooms-list/rooms-list.component';

export const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'booking-request',
        component: BookingRequestComponent,
      },
      {
        path: 'room-management',
        component: RoomsListComponent,
      },
      {
        path: '',
        redirectTo: 'booking-request',
        pathMatch: 'full',
      },
    ],
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];
