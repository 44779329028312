import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import {
  bookingDetailsConverter,
  roomBookingsConverter,
} from './booking-request.converter';
import { environment } from '../../../environments/environment';
import {
  BookingRequestDetailsApiResponseModel,
  BookingRequestDetailsUpdateApiRequestModel,
  ChatMessageApiRequestModel,
  ChatMessageApiResponseModel,
  RoomBookingStatusUpdateApiRequestModel,
  RoomBookingsApiResponseModel,
} from '../model/booking-request-api.model';
import {
  BookingRequestDetailsModel,
  ChatMessageModel,
  RoomBookingModel,
} from '../model/booking-request.model';

@Injectable({
  providedIn: 'root',
})
export class BookingRequestApiService {
  readonly #http = inject(HttpClient);
  readonly #bookingDetailsUrl = environment.bookingDetailsApi;
  readonly #roomBookingsUrl = environment.roomBookingsApi;
  readonly #approveRoomBookingUrl = environment.approveRoomBookingApi;
  readonly #rejectRoomBookingUrl = environment.rejectRoomBookingApi;
  readonly #cancelRoomBookingUrl = environment.cancelRoomBookingApi;
  readonly #chatMessagesUrl = environment.chatMessages;

  public getBookingDetails(
    bookingId: number
  ): Observable<BookingRequestDetailsModel> {
    return this.#http
      .get<BookingRequestDetailsApiResponseModel>(
        this.#bookingDetailsUrl + bookingId
      )
      .pipe(map(bookingDetails => bookingDetailsConverter(bookingDetails)));
  }

  public getRoomBookings(): Observable<RoomBookingModel[]> {
    return this.#http
      .get<RoomBookingsApiResponseModel[]>(this.#roomBookingsUrl)
      .pipe(map(roomBookings => roomBookingsConverter(roomBookings)));
  }

  public approveRoomBooking(request: RoomBookingStatusUpdateApiRequestModel) {
    return this.#http
      .put<BookingRequestDetailsApiResponseModel>(
        this.#approveRoomBookingUrl,
        request
      )
      .pipe(map(bookingDetails => bookingDetailsConverter(bookingDetails)));
  }

  public rejectRoomBooking(request: RoomBookingStatusUpdateApiRequestModel) {
    return this.#http
      .put<BookingRequestDetailsApiResponseModel>(
        this.#rejectRoomBookingUrl,
        request
      )
      .pipe(map(bookingDetails => bookingDetailsConverter(bookingDetails)));
  }

  public cancelRoomBooking(request: RoomBookingStatusUpdateApiRequestModel) {
    return this.#http
      .put<BookingRequestDetailsApiResponseModel>(
        this.#cancelRoomBookingUrl,
        request
      )
      .pipe(map(bookingDetails => bookingDetailsConverter(bookingDetails)));
  }

  public updateRoomBooking(
    request: BookingRequestDetailsUpdateApiRequestModel
  ): Observable<BookingRequestDetailsModel> {
    return this.#http
      .put<BookingRequestDetailsApiResponseModel>(
        this.#bookingDetailsUrl,
        request
      )
      .pipe(map(bookingDetails => bookingDetailsConverter(bookingDetails)));
  }

  public getMessages(bookingId: number): Observable<ChatMessageModel[]> {
    return this.#http.get<ChatMessageModel[]>(
      this.#chatMessagesUrl + bookingId
    );
  }

  public markAllMessagesAsRead(bookingId: number) {
    return this.#http.put<ChatMessageModel[]>(
      this.#chatMessagesUrl + bookingId,
      null
    );
  }

  public postMessage(message: ChatMessageApiRequestModel) {
    return this.#http.post<ChatMessageApiResponseModel[]>(
      this.#chatMessagesUrl,
      message
    );
  }
}
