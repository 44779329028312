import { NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  RdsButtonModule,
  RdsIconComponent,
  RdsRadioButtonModule,
  RdsTableDataSource,
  RdsTableModule,
} from '@rds/angular-components';

import { RoomLayoutsModel } from '../../model/rooms.model';

@Component({
  selector: 'app-layout-table',
  standalone: true,
  imports: [
    RdsButtonModule,
    RdsTableModule,
    RdsRadioButtonModule,
    RdsIconComponent,
    NgOptimizedImage,
  ],
  templateUrl: './layout-table.component.html',
  styleUrl: './layout-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutTableComponent {
  @Input() public set roomLayout(value: RoomLayoutsModel[] | null) {
    if (value) {
      this.dataSource.data = value;
    }
  }
  @Input() public defaultLayout: string | null | undefined = null;
  @Output() public selectedLayout = new EventEmitter<string>();
  @Output() public removeLayout = new EventEmitter<string>();

  protected readonly dataSource = new RdsTableDataSource<RoomLayoutsModel>(
    []
  ).init();
  displayedColumns: (keyof RoomLayoutsModel | 'default' | 'actions')[] = [
    'default',
    'imageURL',
    'layoutName',
    'capacity',
    'setupTime',
    'actions',
  ];

  protected selectLayout(roomLayout: string) {
    this.selectedLayout.emit(roomLayout);
  }

  protected deleteLayout(layoutId: string) {
    this.removeLayout.emit(layoutId);
  }
}
