import { DatePipe, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  RdsAvatarComponent,
  RdsAvatarImageDirective,
  RdsButtonModule,
  RdsCheckboxModule,
  RdsDatepickerModule,
  RdsFormFieldModule,
  RdsIconComponent,
  RdsPopoverModule,
} from '@rds/angular-components';
import { endOfWeek } from 'date-fns/endOfWeek';
import { format } from 'date-fns/format';
import { startOfWeek } from 'date-fns/startOfWeek';
import { filter, switchMap } from 'rxjs';

import { blockingTimeValidator } from './blocking-time.validator';
import { eventDurationValidator } from './event-duration.validator';
import { eventTimeValidator } from './event-time.validator';
import { RoomLayoutsModel } from '../../../room-management/model/rooms.model';
import { RoomsService } from '../../../room-management/services/rooms.service';
import {
  BookingRequestDetailsUpdateApiRequestModel,
  RoomServicesApiResponseEnum,
} from '../../model/booking-request-api.model';
import {
  BookingStatusEnum,
  RoomServicesEnum,
} from '../../model/booking-request.model';
import {
  BookingRequestDetailsViewModel,
  BookingRequestService,
} from '../../services/booking-request.service';

export type BookingDetailsFormGroupModel<T> = FormGroup<{
  [K in keyof T]: FormControl<T[K]>;
}>;

export interface BookingDetailsFormValueModel {
  eventName: string;
  startDate: Date;
  endDate: Date;
  startHour: string;
  endHour: string;
  wbsCode: string | null;
  costCenter: string | null;
  numberOfPeople: number;
  roomLayoutUUID: RoomLayoutsModel | null;
  temService: boolean | null;
  furnitureService: boolean | null;
  cateringService: boolean | null;
  lockerService: boolean | null;
}

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RdsFormFieldModule,
    NgOptimizedImage,
    RdsAvatarComponent,
    RdsAvatarImageDirective,
    RdsIconComponent,
    ReactiveFormsModule,
    RdsDatepickerModule,
    RdsButtonModule,
    RdsPopoverModule,
    RdsCheckboxModule,
    DatePipe,
  ],
})
export class EventDetailsComponent implements OnInit {
  @Input() public set eventDetails(
    value: BookingRequestDetailsViewModel | null
  ) {
    if (value && value !== this.eventDetail) {
      this.setFormValues(value);
      this.bookingDetailsForm.controls.numberOfPeople.addValidators([
        Validators.max(value.roomDetails.capacity),
      ]);
      this.eventDetail = value;
      const min =
        this.eventDetail.roomDetails.manageSpaceConfiguration?.durationRange
          ?.min;
      const max =
        this.eventDetail.roomDetails.manageSpaceConfiguration?.durationRange
          ?.max;
      if (min && max) {
        this.bookingDetailsForm.addValidators(
          eventDurationValidator(min, max) as ValidatorFn
        );
      }
      const blockingTimes =
        this.eventDetail.roomDetails.manageSpaceConfiguration?.blockingTimes;
      if (blockingTimes && blockingTimes.length > 0) {
        this.bookingDetailsForm.addValidators(
          blockingTimeValidator(blockingTimes) as ValidatorFn
        );
      }
    }
  }
  @Input() public set roomLayouts(value: RoomLayoutsModel[]) {
    if (value && value.length > 0 && this.eventDetail) {
      this.layouts.set(value);
      const selectedLayout = value.find(
        layout =>
          layout.layoutDexUUID ===
          this.eventDetail?.bookingDetails.roomLayoutUUID
      );
      this.bookingDetailsForm.controls.roomLayoutUUID.addValidators(
        Validators.required
      );
      if (selectedLayout) {
        this.bookingDetailsForm.controls.roomLayoutUUID.patchValue(
          selectedLayout
        );
      }
    }
  }
  @Output() public isEditModeEnabled = new EventEmitter<boolean>();

  readonly #bookingRequestService = inject(BookingRequestService);
  readonly #roomsService = inject(RoomsService);
  readonly #fb = inject(FormBuilder);
  readonly #destroyRef = inject(DestroyRef);
  protected eventDetail: BookingRequestDetailsViewModel | undefined;
  protected readonly layouts = signal<RoomLayoutsModel[]>([]);
  protected readonly minDate: Date = new Date();
  protected readonly isEditMode = signal(false);
  protected readonly BookingStatusEnum = BookingStatusEnum;

  protected readonly bookingDetailsForm: BookingDetailsFormGroupModel<BookingDetailsFormValueModel> =
    this.#fb.group(
      {
        eventName: this.#fb.nonNullable.control<string>(
          { value: '', disabled: true },
          [Validators.required]
        ),
        startDate: this.#fb.nonNullable.control<Date>(
          { value: new Date(), disabled: true },
          [Validators.required]
        ),
        endDate: this.#fb.nonNullable.control<Date>(
          { value: new Date(), disabled: true },
          [Validators.required]
        ),
        startHour: this.#fb.nonNullable.control<string>(
          { value: '', disabled: true },
          [Validators.required]
        ),
        endHour: this.#fb.nonNullable.control<string>(
          { value: '', disabled: true },
          [Validators.required]
        ),
        wbsCode: this.#fb.control<string>({ value: '', disabled: true }),
        costCenter: this.#fb.control<string>({ value: '', disabled: true }),
        numberOfPeople: this.#fb.nonNullable.control<number>(
          { value: 0, disabled: true },
          [Validators.required, Validators.min(1)]
        ),
        roomLayoutUUID: this.#fb.control<RoomLayoutsModel | null>({
          value: null,
          disabled: true,
        }),
        temService: this.#fb.control<boolean>({ value: false, disabled: true }),
        furnitureService: this.#fb.control<boolean>({
          value: false,
          disabled: true,
        }),
        cateringService: this.#fb.control<boolean>({
          value: false,
          disabled: true,
        }),
        lockerService: this.#fb.control<boolean>({
          value: false,
          disabled: true,
        }),
      },
      { validators: [eventTimeValidator() as ValidatorFn] }
    );

  public ngOnInit() {
    this.bookingDetailsForm.controls.roomLayoutUUID.valueChanges
      .pipe(filter(Boolean), takeUntilDestroyed(this.#destroyRef))
      .subscribe(selectedLayout => {
        this.bookingDetailsForm.controls.numberOfPeople.setValidators([
          Validators.min(selectedLayout.capacity.min),
          Validators.max(selectedLayout.capacity.max),
        ]);
        this.bookingDetailsForm.controls.numberOfPeople.updateValueAndValidity();
      });
  }

  protected switchToEditMode() {
    this.bookingDetailsForm.enable();
    this.isEditMode.set(true);
    this.isEditModeEnabled.emit(true);
    this.bookingDetailsForm.markAllAsTouched();
  }

  protected save() {
    this.bookingDetailsForm.updateValueAndValidity();
    if (this.bookingDetailsForm.valid) {
      this.#bookingRequestService
        .updateBookingRequest(
          this.createUpdateRequestBody(
            this.bookingDetailsForm.value as BookingDetailsFormValueModel
          )
        )
        .pipe(
          switchMap(booking => {
            const weekStart = startOfWeek(
              new Date(booking.startTime.toUTCString()),
              {
                weekStartsOn: 1,
              }
            );
            const weekEnd = endOfWeek(booking.startTime.toUTCString(), {
              weekStartsOn: 1,
            });
            return this.#roomsService.getRoomAvailability(
              this.eventDetail?.roomDetails.calendarId as string,
              format(weekStart, "yyyy-MM-dd'T'00:00:00'Z'"),
              format(weekEnd, "yyyy-MM-dd'T'23:59:59'Z'")
            );
          })
        )
        .subscribe(() => {
          this.isEditMode.set(false);
          this.isEditModeEnabled.emit(false);
          this.bookingDetailsForm.disable();
        });
    }
  }

  protected cancel() {
    this.isEditMode.set(false);
    this.isEditModeEnabled.emit(false);
    if (this.eventDetail) {
      this.setFormValues(this.eventDetail);
    }
    this.bookingDetailsForm.disable();
  }

  private setFormValues(formValue: BookingRequestDetailsViewModel) {
    this.bookingDetailsForm.patchValue({
      eventName: formValue.bookingDetails.eventName,
      startDate: formValue.bookingDetails.startTime,
      endDate: formValue.bookingDetails.endTime,
      startHour: formValue.bookingDetails.startTime
        .toTimeString()
        .substring(0, 5),
      endHour: formValue.bookingDetails.endTime.toTimeString().substring(0, 5),
      wbsCode: formValue.bookingDetails.wbsCode,
      costCenter: formValue.bookingDetails.costCenter,
      numberOfPeople: formValue.bookingDetails.numberOfPeople,
      cateringService: formValue.bookingDetails.services?.some(
        service => service.serviceType === RoomServicesEnum.Catering
      ),
      furnitureService: formValue.bookingDetails.services?.some(
        service => service.serviceType === RoomServicesEnum.Furniture
      ),
      lockerService: formValue.bookingDetails.services?.some(
        service => service.serviceType === RoomServicesEnum.Locker
      ),
    });
  }

  private createUpdateRequestBody(
    formValue: BookingDetailsFormValueModel
  ): BookingRequestDetailsUpdateApiRequestModel {
    const startTime = new Date(
      formValue.startDate.toDateString() + ' ' + formValue.startHour
    );
    const endTime = new Date(
      formValue.endDate.toDateString() + ' ' + formValue.endHour
    );
    const requestStartDate = format(startTime, "yyyy-MM-dd'T'HH:mm:ssxxx");
    const requestEndDate = format(endTime, "yyyy-MM-dd'T'HH:mm:ssxxx");
    const services: { serviceType: RoomServicesApiResponseEnum }[] = [];
    formValue.temService
      ? services.push({ serviceType: RoomServicesApiResponseEnum.Tem })
      : null;
    formValue.cateringService
      ? services.push({ serviceType: RoomServicesApiResponseEnum.Catering })
      : null;
    formValue.furnitureService
      ? services.push({ serviceType: RoomServicesApiResponseEnum.Furniture })
      : null;
    formValue.lockerService
      ? services.push({ serviceType: RoomServicesApiResponseEnum.Locker })
      : null;
    return {
      id: this.eventDetail?.bookingDetails.id
        ? this.eventDetail.bookingDetails.id
        : 0,
      eventName: formValue.eventName,
      numberOfPeople: formValue.numberOfPeople,
      wbsCode: formValue.wbsCode,
      costCenter: formValue.costCenter,
      startTime: requestStartDate,
      endTime: requestEndDate,
      roomLayoutUUID: formValue.roomLayoutUUID
        ? formValue.roomLayoutUUID.layoutDexUUID
        : '',
      services,
    };
  }
}
