<rds-header minimalMargins>
  <rds-divider rds-header-start-divider></rds-divider>
  <rds-header-app-name>Bookly Admin Portal</rds-header-app-name>
  <rds-divider rds-header-end-divider></rds-divider>
  <rds-header-actions>
    <app-notifications-dropdown></app-notifications-dropdown>
    @if (user) {
      <div rdsDropdown [autoClose]="true" placement="bottom-right">
        <rds-avatar class="dropdown-toggle" rdsDropdownToggle trigger="click">
          <img
            rds-avatar-image
            [ngSrc]="user.picture"
            fill
            alt="User avatar"
            referrerpolicy="no-referrer" />
        </rds-avatar>
        <div class="dropdown" *rdsDropdownMenu>
          <span class="dropdown__name">
            {{ user.name }}
          </span>
          <span class="dropdown__email">{{ user.email }}</span>
          <button rds-text-button (click)="authService.logout()">Logout</button>
        </div>
      </div>
    }
  </rds-header-actions>
</rds-header>
<div class="main-panel">
  <rds-sidenav>
    <a
      rds-sidenav-menuitem
      [routerLink]="['booking-request']"
      routerLinkActive="rds-sidenav-menuitem-active">
      <rds-icon namespace="outlined" icon="home"></rds-icon>
      Booking request
    </a>
    <a
      rds-sidenav-menuitem
      [routerLink]="['room-management']"
      routerLinkActive="rds-sidenav-menuitem-active">
      <rds-icon namespace="outlined" icon="download"></rds-icon>
      Room management
    </a>
    <!--    TODO uncomment when proper view will be ready-->
    <!--    <a rds-sidenav-menuitem disabled>-->
    <!--      <rds-icon namespace="outlined" icon="notification"></rds-icon>-->
    <!--      Calendar-->
    <!--    </a>-->
    <!--    <a rds-sidenav-menuitem disabled>-->
    <!--      <rds-icon namespace="outlined" icon="user"></rds-icon>-->
    <!--      User management-->
    <!--    </a>-->
    <a
      rds-sidenav-menuitem
      href="https://sites.google.com/contractors.roche.com/bookly/"
      target="_blank">
      <rds-icon namespace="outlined" icon="help"></rds-icon>
      Help center
    </a>
  </rds-sidenav>
  <main>
    <router-outlet></router-outlet>
  </main>
</div>
