import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { roomCalendarEventsConverter } from './rooms.converter';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import {
  RoomApiResponseModel,
  RoomAvailabilityApiResponseModel,
  RoomDetailsApiResponseModel,
  RoomLayoutUploadApiRequestModel,
  RoomLayoutUploadApiResponseModel,
  RoomLayoutsApiResponseModel,
  RoomSettingApiResponseModel,
  RoomSettingsApiRequestModel,
  RoomsByEmailApiResponseModel,
} from '../model/rooms-api.model';
import {
  RoomCalendarEventsModel,
  RoomDetailsModel,
  RoomLayoutUploadModel,
  RoomLayoutsModel,
  RoomSettingModel,
  RoomsModel,
} from '../model/rooms.model';

@Injectable({
  providedIn: 'root',
})
export class RoomsApiService {
  readonly #authService = inject(AuthService);
  readonly #http = inject(HttpClient);
  readonly #roomsUrl = environment.roomsApi;
  readonly #roomsByEmailUrl = environment.roomsByEmailApi;
  readonly #getRoomLayoutsUrl = environment.getRoomLayoutsApi;
  readonly #userEmail = this.#authService.user?.email;
  readonly #roomDetailsUrl = environment.roomDetailsApi;
  readonly #roomSettingsUrl = environment.roomSettingsApi;
  readonly #roomLayoutsUrl = environment.roomLayoutsApi;
  readonly #roomAvailabilityUrl = environment.roomAvailabilityApi;

  public getRooms(roomIds: string[]): Observable<RoomsModel[]> {
    return this.#http.post<RoomApiResponseModel[]>(this.#roomsUrl, [
      ...roomIds,
    ]);
  }

  public getRoomDetails(roomDexId: string): Observable<RoomDetailsModel> {
    return this.#http.get<RoomDetailsApiResponseModel>(
      this.#roomDetailsUrl + roomDexId
    );
  }

  public getRoomSettings(roomDexId: string): Observable<RoomSettingModel> {
    return this.#http.get<RoomSettingApiResponseModel>(
      this.#roomSettingsUrl + roomDexId
    );
  }

  public updateRoomSettings(
    roomDexId: string,
    request: RoomSettingsApiRequestModel
  ): Observable<RoomSettingModel> {
    return this.#http.put<RoomSettingApiResponseModel>(
      this.#roomSettingsUrl + roomDexId,
      request
    );
  }

  public getRoomsByEmail(): Observable<RoomsByEmailApiResponseModel> {
    return this.#http.get<RoomsByEmailApiResponseModel>(
      this.#roomsByEmailUrl + this.#userEmail
    );
  }

  public uploadLayout(
    layout: RoomLayoutUploadApiRequestModel
  ): Observable<RoomLayoutUploadModel> {
    return this.#http.post<RoomLayoutUploadApiResponseModel>(
      this.#roomLayoutsUrl,
      layout
    );
  }

  public uploadLayoutFile(fileUrl: string, file: File): Observable<never> {
    return this.#http.put<never>(fileUrl, file);
  }

  public deleteLayout(layoutId: string): Observable<never> {
    return this.#http.delete<never>(this.#roomLayoutsUrl + layoutId);
  }

  public getLayouts(roomDexId: string): Observable<RoomLayoutsModel[]> {
    return this.#http.get<RoomLayoutsApiResponseModel[]>(
      this.#getRoomLayoutsUrl + roomDexId + '/layouts'
    );
  }

  public getRoomAvailability(
    calendarId: string,
    dateFrom: string,
    dateTo: string
  ): Observable<RoomCalendarEventsModel[]> {
    return this.#http
      .get<RoomAvailabilityApiResponseModel>(
        this.#roomAvailabilityUrl + calendarId,
        {
          params: { to_date: dateTo, from_date: dateFrom },
        }
      )
      .pipe(map(roomCalendarEventsConverter));
  }
}
