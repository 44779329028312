import { RdsBadgeColor } from '@rds/angular-components';

import {
  BookingRequestDetailsApiResponseModel,
  BookingStatusApiResponseEnum,
  RoomBookingsApiResponseModel,
} from '../model/booking-request-api.model';
import {
  BookingRequestDetailsModel,
  RoomBookingModel,
} from '../model/booking-request.model';

export function roomBookingsConverter(
  roomBookings: RoomBookingsApiResponseModel[]
): RoomBookingModel[] {
  return roomBookings.map(roomBooking => ({
    ...roomBooking,
    createTime: new Date(roomBooking.createTime).toLocaleString(['en-GB'], {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }),
    startDate: new Date(roomBooking.startTime).toLocaleString(['en-GB'], {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    endDate: new Date(roomBooking.endTime).toLocaleString(['en-GB'], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }),
    startTime: new Date(roomBooking.startTime).toLocaleString(['en-GB'], {
      hour: '2-digit',
      minute: '2-digit',
    }),
    endTime: new Date(roomBooking.endTime).toLocaleString(['en-GB'], {
      hour: '2-digit',
      minute: '2-digit',
    }),
    status: statusConverter(roomBooking.status),
  }));
}

export function bookingDetailsConverter(
  bookingDetails: BookingRequestDetailsApiResponseModel
): BookingRequestDetailsModel {
  return {
    ...bookingDetails,
    startTime: new Date(bookingDetails.startTime),
    endTime: new Date(bookingDetails.endTime),
    createTime: new Date(bookingDetails.createTime),
    status: statusConverter(bookingDetails.status),
  };
}

export function statusConverter(status: BookingStatusApiResponseEnum): {
  value: BookingStatusApiResponseEnum;
  text: string;
  color: RdsBadgeColor;
} {
  switch (status) {
    case BookingStatusApiResponseEnum.InProgress:
      return {
        value: BookingStatusApiResponseEnum.InProgress,
        text: 'Awaiting Approval',
        color: 'neutral',
      };
    case BookingStatusApiResponseEnum.Approved:
      return {
        value: BookingStatusApiResponseEnum.Approved,
        text: 'Approved',
        color: 'blue',
      };
    case BookingStatusApiResponseEnum.Rejected:
      return {
        value: BookingStatusApiResponseEnum.Rejected,
        text: 'Rejected',
        color: 'red',
      };
    case BookingStatusApiResponseEnum.Canceled:
      return {
        value: BookingStatusApiResponseEnum.Canceled,
        text: 'Canceled',
        color: 'pink',
      };
    case BookingStatusApiResponseEnum.Deleted:
      return {
        value: BookingStatusApiResponseEnum.Deleted,
        text: 'Deleted',
        color: 'orange',
      };
    default:
      return {
        value: BookingStatusApiResponseEnum.New,
        text: 'New',
        color: 'yellow',
      };
  }
}
