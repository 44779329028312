import { DatePipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { RdsBadgeComponent, RdsDialogService } from '@rds/angular-components';

import { BookingRequestDetailsComponent } from '../../../booking-request/views/booking-request-details/booking-request-details.component';
import {
  NotificationSourceTypeEnum,
  NotificationStatusEnum,
  NotificationsApiResponseModel,
} from '../../model/notifications-api.model';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [NgClass, RdsBadgeComponent, DatePipe],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input() notification: NotificationsApiResponseModel | null = null;

  readonly #dialogService = inject(RdsDialogService);
  readonly #notificationsService = inject(NotificationsService);
  protected readonly NotificationStatusEnum = NotificationStatusEnum;
  protected readonly NotificationSourceTypeEnum = NotificationSourceTypeEnum;

  protected handleNotificationClick() {
    if (this.notification?.status === NotificationStatusEnum.Unread) {
      this.#notificationsService
        .markNotificationAsRead(this.notification.notificationId)
        .subscribe();
      this.notification.status = NotificationStatusEnum.Read;
      this.#notificationsService.decreaseCounter();
    }
    this.#dialogService.open(BookingRequestDetailsComponent, {
      disableClose: true,
      size: 'xl',
      maxHeight: '100%',
      data: {
        bookingId: this.notification?.attributes.bookingId,
        roomDexId: this.notification?.attributes.roomDexId,
        setActiveTab: this.notification?.sourceService,
      },
    });
  }
}
