import { NgOptimizedImage } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  RdsButtonModule,
  RdsIconsModule,
  RdsProgressSpinnerModule,
  RdsSortDirective,
  RdsTableDataSource,
  RdsTableModule,
} from '@rds/angular-components';

import { RoomsModel } from '../../model/rooms.model';

@Component({
  selector: 'app-rooms-list-table',
  standalone: true,
  imports: [
    RdsTableModule,
    RdsIconsModule,
    RdsButtonModule,
    NgOptimizedImage,
    RdsProgressSpinnerModule,
  ],
  templateUrl: './rooms-list-table.component.html',
  styleUrl: './rooms-list-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomsListTableComponent implements AfterViewInit {
  @Input() public set rooms(value: RoomsModel[] | null) {
    if (value) {
      this.dataSource.data = value;
    }
  }
  @Output() public rowClicked = new EventEmitter<RoomsModel>();

  protected readonly dataSource = new RdsTableDataSource<RoomsModel>([]).init();

  protected readonly displayedColumns: (
    | keyof RoomsModel
    | 'actions'
    | 'multiselect'
    | 'image'
  )[] = ['name', 'image', 'description', 'type', 'capacity'];
  @ViewChild(RdsSortDirective) sort: RdsSortDirective | null = null;

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  protected clickRowHandler(rowData: RoomsModel) {
    this.rowClicked.emit(rowData);
  }
}
