import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { filter, finalize, switchMap, tap } from 'rxjs';

import { NotificationsApiService } from './notifications-api.service';
import { AuthService } from '../../auth/auth.service';
import {
  NotificationStatusEnum,
  NotificationsApiResponseModel,
} from '../model/notifications-api.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  readonly #authService = inject(AuthService);
  readonly #notificationsApiService = inject(NotificationsApiService);

  readonly #unreadCount = signal('0');
  readonly #allNotifications: WritableSignal<NotificationsApiResponseModel[]> =
    signal([]);
  readonly #isLoading = signal(false);
  readonly #latestFiveNotifications: WritableSignal<
    NotificationsApiResponseModel[]
  > = signal([]);
  #counter = 0;

  public get isLoading() {
    return this.#isLoading.asReadonly();
  }

  public get unreadCount() {
    return this.#unreadCount.asReadonly();
  }

  public get allNotifications() {
    return this.#allNotifications.asReadonly();
  }

  public get latestNotifications() {
    return this.#latestFiveNotifications.asReadonly();
  }

  public getNotifications() {
    this.#isLoading.set(true);
    this.#counter = 0;
    return this.#notificationsApiService
      .getNotifications(this.#authService.user?.email as string)
      .pipe(
        tap(res => {
          res.forEach(notification => {
            if (notification.status === NotificationStatusEnum.Unread) {
              this.#counter++;
            }
          });
          this.#latestFiveNotifications.set(res.slice(0, 5));
          this.#allNotifications.set(res);
          this.#unreadCount.set(this.#counter.toString());
        }),
        finalize(() => this.#isLoading.set(false))
      );
  }

  public updateNotifications() {
    return this.#notificationsApiService
      .getNotifications(this.#authService.user?.email as string)
      .pipe(
        filter(
          notifications =>
            notifications.length > 0 &&
            notifications[0].notificationId !==
              this.allNotifications()[0].notificationId
        ),
        tap(notifications => {
          this.#counter = 0;
          notifications.forEach(notification => {
            if (notification.status === NotificationStatusEnum.Unread) {
              this.#counter++;
            }
          });
          this.#latestFiveNotifications.set(notifications.slice(0, 5));
          this.#allNotifications.set(notifications);
          this.#unreadCount.set(this.#counter.toString());
          new Notification('Bookly Admin Panel', {
            body: 'New notification received',
          });
        })
      );
  }

  public markNotificationAsRead(notificationId: number) {
    return this.#notificationsApiService.markNotificationAsRead(
      this.#authService.user?.email as string,
      notificationId
    );
  }

  public markAllNotificationAsRead() {
    this.#isLoading.set(true);
    return this.#notificationsApiService
      .markAllNotificationAsRead(this.#authService.user?.email as string)
      .pipe(
        switchMap(() => this.getNotifications()),
        finalize(() => this.#isLoading.set(false))
      );
  }

  public decreaseCounter() {
    this.#counter--;
    this.#unreadCount.set(this.#counter.toString());
  }
}
