import { NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import {
  RdsAvatarComponent,
  RdsAvatarImageDirective,
  RdsButtonModule,
  RdsDividerModule,
  RdsDropdownModule,
  RdsHeaderModule,
  RdsIconComponent,
  RdsSidenavModule,
} from '@rds/angular-components';
import { interval, switchMap } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { NotificationsService } from '../notifications/services/notifications.service';
import { NotificationsDropdownComponent } from '../notifications/views/notifications-dropdown/notifications-dropdown.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RdsHeaderModule,
    RdsDividerModule,
    NotificationsDropdownComponent,
    RdsDropdownModule,
    RdsAvatarComponent,
    RdsAvatarImageDirective,
    RdsButtonModule,
    RdsSidenavModule,
    RouterLinkActive,
    RouterLink,
    RdsIconComponent,
    RouterOutlet,
    NgOptimizedImage,
  ],
})
export class DashboardComponent implements OnInit {
  protected readonly authService = inject(AuthService);
  readonly #notificationsService = inject(NotificationsService);
  protected readonly user = this.authService.user;

  ngOnInit() {
    this.#notificationsService.getNotifications().subscribe();
    interval(300000)
      .pipe(switchMap(() => this.#notificationsService.updateNotifications()))
      .subscribe();
  }
}
